<template>
  <div style="height: 100%">
    <bread></bread>
    <div class="center-content">
      <div class="iq-card-body">
        <div class="table-top flex-space-between">
          <el-page-header @back="goBack" :content="type == 1 ? '收费详情' : '退费'"> </el-page-header>
          <div>
            <!-- <el-button type="primary" size="small" @click="toCharge" v-if="type == 1">收费</el-button> -->
            <!-- <el-button type="primary" size="small" @click="toRefundAll"
              v-if="type == 2 && detailInfo.discountsMoney > 0 && detailInfo.state == 1">退费</el-button>
            <el-button type="primary" size="small" @click="toRefund"
              v-if="type == 2 && detailInfo.discountsMoney == 0 && detailInfo.state == 1">退费</el-button> -->
            <el-button type="primary" size="small" @click="toRefund" v-if="type == 3 && (detailInfo.state == 1 || detailInfo.state == -4)">退费</el-button>
            <el-button type="danger" size="small" @click="toAuditRefund" v-if="type == 2 && detailInfo.state == -2">拒绝</el-button>
            <el-button type="primary" size="small" @click="toAgreeRefund" v-if="type == 2 && detailInfo.state == -2">同意</el-button>
          </div>
        </div>
        <div class="table-line"></div>
        <div class="table-content">
          <div class="top-info flex-space-between">
            <div class="left-i">
              <div class="flex-start">
                <img v-if="patientInfo.sex" src="@/assets/img/touxiang03.png" />
                <img v-else src="@/assets/img/touxiang04.png" />
                <div class="userName">
                  {{ detailInfo.patientName ? detailInfo.patientName : '无' }}
                </div>
              </div>
              <div class="info-div">
                <span class="t1">
                  <span class="t2">性别：</span>
                  <span v-if="patientId">{{ patientInfo.sex == true ? '男' : '女' }}</span>
                  <span v-else>无</span>
                </span>
                <span class="t1"><span class="t2">年龄：</span>{{ patientInfo.age ? patientInfo.age : '无' }}</span>
                <span><i class="el-icon-phone t2"></i>：{{ patientInfo.phone ? patientInfo.phone : '无' }}</span>
              </div>
            </div>
            <div class="center-i">
              <div class="t1">
                <span class="t2">就诊类型：</span>
                <template v-if="detailInfo.diagnoseType">复诊</template>
                <template v-else>初诊</template>
              </div>
              <div class="t1">
                <span class="t2">就诊时间：</span>
                <span>{{ mZPrescriptionInfo.mzPrescriptionOut.addTime | timefilters }}</span>
              </div>
              <div class="t1">
                <span class="t2">看诊医生：</span>
                <span>{{ detailInfo.doctorName ? detailInfo.doctorName : '无' }}</span>
                <span v-if="mZPrescriptionInfo.mzPrescriptionOut.userPhone">【{{ mZPrescriptionInfo.mzPrescriptionOut.userPhone }}】</span>
              </div>
            </div>
            <div class="right-i">
              <div class="t1">
                <span class="t2">审核医生：</span>
                <span>{{ mZPrintPrescription.doctorName ? mZPrintPrescription.doctorName : '无' }}</span>
                <span v-if="mZPrintPrescription.doctorPhone">【{{ mZPrintPrescription.doctorPhone }}】</span>
              </div>
              <div class="t1">
                <span class="t2">审核时间：</span>
                <span>{{ mZPrescriptionInfo.mzPrescriptionOut.auditTime | timefilters }}</span>
              </div>
              <div class="t1">
                <span class="t2">诊断：</span>
                <span>{{ detailInfo.diagnoseResult ? detailInfo.diagnoseResult : '无' }}</span>
              </div>
            </div>
          </div>
          <el-divider v-if="type == 1"></el-divider>
          <div class="numDiv numDiv2" v-if="type == 2 || type == 3">
            <div class="flex-start" v-for="item in detailInfo.payments">
              <!-- type 付款类型 0收费 1退费 2还款 -->
              <div class="flex-start desc">
                <div class="t1">
                  <span class="t2">
                    <span v-if="item.type == 0" class="type0">收费：</span>
                    <span v-if="item.type == 1" class="type1">退费：</span>
                    <span v-if="item.type == 2" class="type2">还款：</span>
                    时间：</span
                  >{{ item.paySuccessTime | timefilters }}
                </div>
                <div><span class="t2"> 操作员：</span>{{ item.userName }}</div>
              </div>
              <div class="flex-start center-num desc">
                <!-- <div class="t1"><span class="t2">总金额：</span>{{ item.totalMoney ? item.totalMoney.toFixed(2) : '0.00' }}
                </div> -->
                <!-- <div class="t1"><span class="t2">优惠：</span>¥{{ item.discountsMoney ? item.discountsMoney.toFixed(2) :
                  '0.00' }}</div> -->
                <!-- <div v-if="item.type == 0">
                  <span class="t2">应收：</span>
                  <span>¥{{ (item.totalMoney - item.discountsMoney).toFixed(2) }}</span>
                </div> -->
                <div v-if="item.type == 1">
                  <span class="t2">应退：</span>
                  <span>¥{{ (item.totalMoney - item.discountsMoney).toFixed(2) }}</span>
                </div>
                <!-- <div v-if="item.type == 2"><span class="t2">应还：</span>¥{{ item.payMoney ? item.payMoney.toFixed(2) :
                  '0.00' }}</div> -->
              </div>
              <div class="flex-end right-num desc">
                <div class="t1">
                  付款方式：
                  <!-- <span class="num2" v-for="(item, i) in detailInfo.totalPayWay">{{ i }}:¥{{ item}};</span> -->
                  <!-- <span class="t3" v-if="item.payMoney >= 0">欠款 ¥{{ item.debtMoney ?
                    item.debtMoney.toFixed(2) : '0.00' }} ;</span> -->
                  <!-- <span class="t3" v-if="item.payMoney < 0">抵扣111 ¥{{ item.totalMoney ?
                    item.totalMoney.toFixed(2) : '0.00' }} ;</span> -->
                </div>
                <div class="t3">
                  <span v-if="item.payWay == 0">
                    <span v-if="detailInfo.orderType == 0">现金</span>
                    <span v-else>余额</span>
                  </span>
                  <span v-if="item.payWay == 1">微信</span>
                  <span v-if="item.payWay == 2">支付宝</span>
                  <span v-if="item.payWay == 3">银行卡</span>
                  <span v-if="item.payWay == 4">其他</span>
                  <span v-if="item.payMoney >= 0">¥{{ item.payMoney ? item.payMoney.toFixed(2) : '0.00' }}</span>
                  <span v-if="item.payMoney < 0">¥0.00</span>
                </div>
              </div>
            </div>
          </div>
          <div class="title" v-if="orderGrouped[2].length > 0">检查治疗项</div>
          <el-table :data="orderGrouped[2]" style="width: 100%" :header-cell-style="{ background: '#F5F7FC' }" v-if="orderGrouped[2].length > 0">
            <el-table-column prop="entityName" label="检查名称"></el-table-column>
            <el-table-column prop="money" label="单价(元)" align="center">
              <template slot-scope="scope">
                {{ scope.row.money.toFixed(2) }}
              </template>
            </el-table-column>
            <el-table-column prop="amount" label="数量" align="center"></el-table-column>
            <el-table-column prop="countMoney" label="总金额(元)" align="center">
              <template slot-scope="scope">
                {{ scope.row.countMoney.toFixed(2) }}
              </template>
            </el-table-column>
            <el-table-column prop="addTime" label="状态" align="center" width="150px">
              <template slot-scope="scope">
                <el-tag type="warning" v-if="scope.row.orderState == 0" size="small">待支付</el-tag>
                <el-tag type="success" v-if="scope.row.orderState == 1" size="small">已支付</el-tag>
                <el-tag type="danger" v-if="scope.row.orderState == -1" size="small">已退费</el-tag>
              </template>
            </el-table-column>
          </el-table>
          <div class="title" v-if="orderGrouped[1].length > 0">处方药</div>
          <el-table :data="orderGrouped[1]" style="width: 100%" :header-cell-style="{ background: '#F5F7FC' }" v-if="orderGrouped[1].length > 0">
            <el-table-column prop="prescriptionType" label="处方类型">
              <template slot-scope="scope">
                <p v-if="scope.row.prescriptionType == 0">方剂处方</p>
                <p v-if="scope.row.prescriptionType == 1">成药处方</p>
                <p v-if="scope.row.prescriptionType == 2">输液处方</p>
              </template>
            </el-table-column>
            <el-table-column prop="prescriptionName" label="处方名称" align="center"></el-table-column>
            <el-table-column prop="entityName" label="药品名称" align="center"></el-table-column>
            <el-table-column prop="specification" label="规格" align="center">
              <template slot-scope="scope">
                <p v-if="scope.row.specification">{{ scope.row.specification }}/{{ scope.row.specificationUnit }}</p>
              </template>
            </el-table-column>
            <el-table-column prop="productFactory" label="厂家" align="center" show-overflow-tooltip></el-table-column>
            <el-table-column prop="money" label="单价(元)" align="center">
              <template slot-scope="scope"> {{ scope.row.money.toFixed(2) }}/{{ scope.row.isSeparate ? scope.row.separateNumberUnit : scope.row.specificationUnit }} </template>
            </el-table-column>
            <el-table-column prop="amount" label="数量" align="center">
              <template slot-scope="scope"> {{ scope.row.amount }}{{ scope.row.isSeparate ? scope.row.separateNumberUnit : scope.row.specificationUnit }} </template>
            </el-table-column>
            <el-table-column prop="countMoney" label="总金额(元)" align="center">
              <template slot-scope="scope">
                {{ scope.row.countMoney.toFixed(2) }}
              </template>
            </el-table-column>
            <el-table-column prop="dosage" label="剂数" align="center">
              <template slot-scope="scope">
                {{ scope.row.dosage ? scope.row.dosage : '' }}
              </template>
            </el-table-column>
            <el-table-column prop="addTime" label="状态" align="center" width="150px">
              <template slot-scope="scope">
                <el-tag type="warning" v-if="scope.row.orderState == 0" size="small">待支付</el-tag>
                <el-tag type="success" v-if="scope.row.orderState == 1" size="small">已支付</el-tag>
                <el-tag type="danger" v-if="scope.row.orderState == -1" size="small">已退费</el-tag>
              </template>
            </el-table-column>
          </el-table>
          <div class="title" v-if="orderGrouped[0].length > 0">直接售药</div>
          <el-table :data="orderGrouped[0]" style="width: 100%" :header-cell-style="{ background: '#F5F7FC' }" v-if="orderGrouped[0].length > 0" @selection-change="handleSelectionChange($event, 0)" ref="multipleSelection0" @select-all="selectAllChange($event, 0)">
            <el-table-column type="selection" width="55" :selectable="checkboxSelect" v-if="type == 3"> </el-table-column>
            <el-table-column prop="entityName" label="药品名称"></el-table-column>
            <el-table-column prop="specification" label="规格" align="center">
              <template slot-scope="scope">
                <p v-if="scope.row.specification">{{ scope.row.specification }}/{{ scope.row.specificationUnit }}</p>
              </template>
            </el-table-column>
            <el-table-column prop="productFactory" label="厂家" align="center" show-overflow-tooltip></el-table-column>
            <el-table-column prop="money" label="单价(元)" align="center">
              <template slot-scope="scope"> {{ scope.row.money.toFixed(2) }}/{{ scope.row.isSeparate ? scope.row.separateNumberUnit : scope.row.specificationUnit }} </template>
            </el-table-column>
            <el-table-column prop="amount" label="数量" align="center">
              <template slot-scope="scope"> {{ scope.row.amount }}{{ scope.row.isSeparate ? scope.row.separateNumberUnit : scope.row.specificationUnit }} </template>
            </el-table-column>
            <el-table-column prop="countMoney" label="总金额(元)" align="center">
              <template slot-scope="scope">
                {{ scope.row.countMoney.toFixed(2) }}
              </template>
            </el-table-column>
            <el-table-column prop="addTime" label="状态" align="center" width="150px">
              <template slot-scope="scope">
                <el-tag type="warning" v-if="scope.row.orderState == 0" size="small">待支付</el-tag>
                <el-tag type="success" v-if="scope.row.orderState == 1" size="small">已支付</el-tag>
                <el-tag type="danger" v-if="scope.row.orderState == -1" size="small">已退费</el-tag>
              </template>
            </el-table-column>
          </el-table>
          <div class="title" v-if="orderGrouped[99].length > 0">其他收费</div>
          <el-table :data="orderGrouped[99]" style="width: 100%" :header-cell-style="{ background: '#F5F7FC' }" v-if="orderGrouped[99].length > 0">
            <el-table-column prop="entityName" label="费用名称">
              <template slot-scope="scope"> {{ scope.row.entityName }}<span style="color: red; font-size: 12px; margin-left: 10px" v-if="scope.row.orderType == 5">(不可退)</span> </template>
            </el-table-column>
            <el-table-column prop="countMoney" label="金额(元)" align="center">
              <template slot-scope="scope">
                {{ scope.row.countMoney.toFixed(2) }}
              </template>
            </el-table-column>
            <el-table-column prop="addTime" label="状态" align="center" width="150px">
              <template slot-scope="scope">
                <el-tag type="warning" v-if="scope.row.orderState == 0" size="small">待支付</el-tag>
                <el-tag type="success" v-if="scope.row.orderState == 1" size="small">已支付</el-tag>
                <el-tag type="danger" v-if="scope.row.orderState == -1" size="small">已退费</el-tag>
              </template>
            </el-table-column>
          </el-table>
          <div class="numDiv flex-end" v-if="type == 1">
            <div class="num1">总金额:</div>
            <div class="inputNum" style="margin-right: 50px">
              ￥
              <span style="font-size: 25px"> {{ detailInfo.totalMoney ? detailInfo.totalMoney.toFixed(2) : '0.00' }}</span>
            </div>
          </div>
          <div class="numDiv flex-space-between" v-if="type == 2 || type == 3">
            <div class="flex-start">
              <div>总金额：¥{{ detailInfo.totalMoney ? detailInfo.totalMoney.toFixed(2) : '0.00' }}</div>
            </div>
            <div class="flex-end" v-if="type==3">
              <div class="" style="margin-left: 20px">退费金额：<el-input-number v-model="refund.totalMoney" size="small" controls-position="right" :precision="2" :step="0.1" class="num2" disabled></el-input-number></div>
            </div>
            <div class="flex-end" v-else>
              <div class="" style="margin-left: 20px">退费金额：<el-input-number v-model="detailInfo.showTotalMoney" size="small" controls-position="right" :precision="2" :step="0.1" class="num2" disabled></el-input-number></div>
            </div>
          </div>
          <div class="infoDiv flex-space-between">
            <div>
              <span class="t1">取药方式：</span>
              <span class="t2" v-if="detailInfo.isSelef">自取 </span>
              <span class="t2" v-else>快递 收件人信息：{{ detailInfo.takeAddress ? detailInfo.takeAddress : '无' }} </span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <el-dialog title="收费" :visible.sync="setChargeDialog" width="30%">
      <el-form class="dialog-form" :model="charge" ref="chargeForm" :label-width="formLabelWidth" :rules="rules">
        <el-form-item label="应收金额" style="color: #ff0000" class="numT">
          <!-- <span style="font-size: 25px;" v-if="charge.discountsMoney"><span style="font-size: 14px;">￥</span>{{
            charge.totalMoney.toFixed(2) - charge.discountsMoney.toFixed(2) }}</span> -->
          <span style="font-size: 25px"><span style="font-size: 14px">￥</span>{{ charge.totalMoney ? charge.totalMoney.toFixed(2) : '0.00' }}</span>
        </el-form-item>
        <!-- <el-form-item label="挂账">
          <el-switch v-model="charge.credit" @change="handleChange"></el-switch>
        </el-form-item> -->
        <template v-if="charge.credit">
          <el-form-item label="姓名" prop="debtUserName">
            <el-input v-model="charge.debtUserName" style="width: 80%" clearable></el-input>
          </el-form-item>
          <el-form-item label="手机号" prop="debtUserPhone">
            <el-input v-model="charge.debtUserPhone" style="width: 80%" clearable></el-input>
          </el-form-item>
          <el-form-item label="挂账金额" prop="debtMoney">
            <el-input-number v-model="charge.debtMoney" size="small" controls-position="right" :precision="2" :step="1" :min="1" class="leftInput" @change="checkDebtMoney" style="width: 150px"></el-input-number>
          </el-form-item>
        </template>
        <el-form-item label="付款方式">
          <el-radio-group v-model="charge.payWay">
            <el-radio :label="0">现金</el-radio>
            <el-radio :label="1">微信</el-radio>
            <el-radio :label="2">支付宝</el-radio>
            <el-radio :label="3">银行卡</el-radio>
            <el-radio :label="5">其他</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="付款金额" prop="payment">
          <el-input-number v-model="charge.payment" size="small" controls-position="right" :precision="2" :step="1" class="leftInput" @change="checkMoney" style="width: 150px"></el-input-number>
        </el-form-item>
        <el-form-item label="找零">
          {{ charge.change ? charge.change.toFixed(2) : '0.00' }}
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="CloseBtn('chargeForm')">取 消</el-button>
        <el-button type="primary" @click="SaveBtn('chargeForm')">确 定</el-button>
      </div>
    </el-dialog>
    <el-dialog title="退费" :visible.sync="setRefundDialog" width="30%">
      <el-form class="dialog-form" :model="refund" ref="chargeForm" :label-width="formLabelWidth" :rules="rules">
        <!-- <el-form-item label="挂账金额" style="color: #FF0000;" class="numT">
          ￥{{ detailInfo.debtMoney ? detailInfo.debtMoney.toFixed(2) : '0.00' }}
        </el-form-item>
        <el-form-item label="抵扣挂账" style="color: #FF0000;" class="numT">
          ￥{{ detailInfo.debtMoney ? detailInfo.debtMoney.toFixed(2) : '0.00' }}
        </el-form-item> -->
        <el-form-item label="退费金额" style="color: #ff0000" class="numT">
          <!-- ￥{{ (refund.totalMoney - detailInfo.debtMoney - detailInfo.discountsMoney).toFixed(2) }} -->
          ￥{{ refund.totalMoney ? refund.totalMoney : '0.00' }}
        </el-form-item>
        <el-form-item label="退费方式" prop="type">
          <el-radio-group v-model="refund.payments[0].payWay">
            <el-radio :label="0">现金</el-radio>
            <el-radio :label="1">微信</el-radio>
            <el-radio :label="2">支付宝</el-radio>
            <el-radio :label="3">银行卡</el-radio>
            <el-radio :label="4">其他</el-radio>
          </el-radio-group>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="closeRefundBtn('chargeForm')">取 消</el-button>
        <el-button type="primary" @click="saveRefund('chargeForm')">确 定</el-button>
      </div>
    </el-dialog>
    <el-dialog title="退费" :visible.sync="setPCRefundDialog" width="30%">
      <el-form class="dialog-form" :model="refund" ref="chargeForm" :label-width="formLabelWidth" :rules="rules">
        <el-form-item label="退费金额" style="color: #ff0000" class="numT" v-if="refund.isOk"> ￥{{ refund.totalMoney ? refund.totalMoney : '0.00' }} </el-form-item>
        <!-- <el-form-item label="退费方式" prop="type" v-if="refund.isOk">
          <el-radio-group v-model="refund.payments[0].payWay" disabled>
            <el-radio :label="0">余额</el-radio>
          </el-radio-group>
        </el-form-item> -->
        <el-form-item label="拒绝原因" prop="auditRemark" v-if="!refund.isOk">
          <el-input type="textarea" v-model="refund.auditRemark" size="small"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="closeRefundBtn('chargeForm')">取 消</el-button>
        <el-button type="primary" @click="saveRefund('chargeForm')">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import { Outpatient } from '@/components/DrugDomain/Outpatient'
import { Patient } from '@/components/HospitalDomain/Patient/Patient'
export default {
  inject: ['reload'],
  data() {
    var outpatient = new Outpatient(this.TokenClient, this.Services.Drug)
    var patient = new Patient(this.TokenClient, this.Services.Authorization)
    return {
      OutpatientDomain: outpatient,
      patientDomain: patient,
      tableData: [],
      detailInfo: {
        gender: 0,
      },
      userInfo: {},
      setChargeDialog: false,
      setPCRefundDialog: false,
      setRefundDialog: false,
      formLabelWidth: '90px',
      form: {
        id: 0,
        payments: [
          {
            discountsMoney: 0, //优惠金额
            debtUserName: null,
            debtUserPhone: null,
            debtMoney: 0, //挂账总金额
            payWay: 0, //0 现金 1微信支付，2支付宝，3银行卡 4 挂账 5 其他
            payMoney: 0, //支付金额
            totalMoney: 0,
            payment: null, //输入金额
            change: 0, //找零
            credit: false,
          },
        ],
        details: [],
      },
      charge: {
        id: 0,
        details: [],
        discountsMoney: 0, //优惠金额
        debtUserName: null,
        debtUserPhone: null,
        debtMoney: 0, //挂账总金额
        payWay: 0, //0 现金 1微信支付，2支付宝，3银行卡 4 挂账 5 其他
        payMoney: 0, //支付金额
        totalMoney: 0,
        payment: null, //输入金额
        change: 0, //找零
        credit: false,
      },
      refund: {
        id: parseInt(this.$route.query.orderId),
        totalMoney: null,
        isOk: true,
        auditRemark: '',
        details: [],
        payments: [
          {
            payWay: 0,
            payMoney: 0,
          },
        ],
      },
      orderId: this.$route.query.orderId,
      type: this.$route.query.type, //1收费2退费3直接售药退费
      selectDetail: [],
      rules: {
        debtUserName: [{ required: true, message: '请输入姓名', trigger: 'blur' }],
        debtUserPhone: [
          { required: true, message: '请输入手机号', trigger: 'blur' },
          {
            pattern: /^((0\d{2,3}-\d{7,8})|(1[3456789]\d{9}))$/,
            message: '请输入合法手机号',
            trigger: 'blur',
          },
        ],
        debtMoney: [{ required: true, message: '请输入挂账金额', trigger: 'blur' }],
        payment: [{ required: true, message: '请输入付款金额', trigger: 'blur' }],
        auditRemark: [{ required: true, message: '请输入拒绝原因', trigger: 'blur' }],
      },
      orderGrouped: { 0: [], 1: [], 2: [], 3: [], 4: [], 5: [], 6: [], 7: [], 99: [] },
      multipleSelection0: [],
      multipleSelection1: [],
      multipleSelection2: [],
      multipleSelection3: [],
      multipleSelection4: [],
      multipleSelection5: [],
      patientId: 0,
      patientDiagnoseId: 0,
      patientInfo: {},
      patientDiagnoseInfo: {},
      mZPrescriptionInfo: {
        mzCheckItems: [],
        mzOtherCharges: [],
        mzPrescriptionOut: {
          mzPrescriptionSYGroup: [],
          mzPrescriptionCY: [],
          mzPrescriptionYP: [],
        },
      },
      mZPrintPrescription: {},
    }
  },
  watch: {
    // charge: {
    //   handler (newValue) {
    //     let totalUserPayMoney = 0
    //     if (this.charge.details.length > 0) {
    //       this.charge.details.forEach((item) => {
    //         item.countMoney = item.money * item.amount
    //         //处方类型 0方剂(饮片) 处方 1 成药处方 2 输液处方
    //         if (item.orderType == 1 && item.prescriptionType == 0) {
    //           totalUserPayMoney =
    //             totalUserPayMoney + item.countMoney * parseInt(item.dosage)
    //         } else {
    //           totalUserPayMoney = totalUserPayMoney + item.countMoney
    //         }
    //       })
    //     }
    //     this.charge.totalMoney = totalUserPayMoney
    //   },
    //   deep: true,
    // },
  },
  mounted() {
    if (this.type == 1) {
      this.getChargeDetail()
    } else {
      this.paymentDetail()
    }
  },
  methods: {
    CloseBtn(formName) {
      this.setChargeDialog = false
      this.$refs[formName].resetFields()
    },
    SaveBtn(formName) {
      var _this = this
      _this.$refs[formName].validate((valid) => {
        if (valid) {
          const loading = this.$loading({
            lock: true,
            background: 'rgba(0, 0, 0, 0.5)',
          })
          _this.charge.payMoney = this.charge.totalMoney - this.charge.discountsMoney - this.charge.debtMoney
          let form = {
            id: _this.charge.id,
            details: _this.charge.details,
            payments: [
              {
                discountsMoney: _this.charge.discountsMoney,
                debtUserName: _this.charge.debtUserName,
                debtUserPhone: _this.charge.debtUserPhone,
                debtMoney: _this.charge.debtMoney,
                payWay: _this.charge.payWay,
                payMoney: _this.charge.payMoney,
                totalMoney: _this.charge.totalMoney,
              },
            ],
          }
          console.log('form', form)
          _this.OutpatientDomain.DrugPayMent(
            form,
            function (data) {
              loading.close()
              _this.$message({
                message: '收费成功',
                type: 'success',
              })
              if (_this.detailInfo.details.length == form.details.length) {
                _this.$router.push('./NotChargedList')
              } else {
                _this.reload()
              }
            },
            function (error) {
              loading.close()
              let jsonObj = JSON.parse(error.msg)
              _this.$message({
                message: jsonObj.msg,
                type: 'error',
              })
              console.log(error)
            }
          )
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
    checkDebtMoney() {
      this.charge.payment = this.charge.totalMoney - this.charge.discountsMoney - this.charge.debtMoney
      this.charge.change = 0
    },
    checkMoney() {
      let payMoney = this.charge.totalMoney - this.charge.discountsMoney
      if (this.charge.credit) {
        payMoney = payMoney - this.charge.debtMoney
      }
      if (this.charge.payment < payMoney) {
        this.$message({
          message: `付款金额不能小于${payMoney}`,
          type: 'error',
        })
        this.charge.payment = undefined
        return
      } else {
        this.charge.change = this.charge.payment + this.charge.debtMoney - (this.charge.totalMoney - this.charge.discountsMoney)
        if (this.charge.credit) {
          this.charge.change = this.charge.payment + this.charge.debtMoney - (this.charge.totalMoney - this.charge.discountsMoney)
        } else {
          this.charge.change = this.charge.payment - (this.charge.totalMoney - this.charge.discountsMoney)
        }
      }
    },
    handleChange() {
      this.charge.debtUserName = null
      this.charge.debtUserPhone = null
      this.charge.debtMoney = undefined
      this.charge.change = null
      if (!this.charge.credit) {
        this.charge.payment = this.charge.totalMoney - this.charge.discountsMoney
        this.charge.debtMoney = 0
      }
    },
    handleSelectionChange(val, orderType) {
      this.multipleSelection0 = val
      console.log(val)
      this.computeSubData()
    },
    computeSubData() {
      this.refund.details = []
      this.refund.totalMoney = 0
      this.refund.details = [...this.multipleSelection0]
      this.refund.details.forEach((item) => {
        this.refund.totalMoney = this.refund.totalMoney + item.countMoney
      })
    },
    /**
     * 根据状态禁用勾选退款
     */
    checkboxSelect(row, rowIndex) {
      // 0 待支付 1 已支付 -1 已退款
      if (this.type == 3) {
        if (row.orderState == 1 && this.detailInfo.discountsMoney == 0) {
          return true
        } else {
          return false
        }
      } else if (this.type == 1) {
        return false
      }
    },
    initData() {
      this.charge.id = this.detailInfo.id
      this.charge.details = []
      this.charge.discountsMoney = 0 //优惠金额
      this.charge.debtUserName = null
      this.charge.debtUserPhone = null
      this.charge.debtMoney = 0 //挂账总金额
      this.charge.payWay = 0 //0 现金 1微信支付，2支付宝，3银行卡 4 挂账 5 其他
      this.charge.payMoney = 0 //支付金额
      this.charge.totalMoney = 0
      this.charge.payment = null //输入金额
      this.charge.change = 0 //找零
      this.charge.credit = false
    },
    /**
     * 待收费详情
     */
    getChargeDetail() {
      var _this = this
      _this.OutpatientDomain.DrugPayMentDetail(
        this.orderId,
        function (data) {
          _this.detailInfo = data.data
          console.log(_this.detailInfo.totalMoney)
          _this.patientId = _this.detailInfo.patientId
          _this.patientDiagnoseId = _this.detailInfo.patientDiagnoseId
          if (_this.patientId) {
            _this.getDetail()
            _this.getPatientDiagnose()
            _this.getMZPrescriptionDetail()
          }
          _this.initData()
          //orderType 0直接售药 1处方 2检查治疗项 3附加收费 4代煎费用 5审方费用 6快递费 7包装费
          _this.orderGrouped = { 0: [], 1: [], 2: [], 3: [], 4: [], 5: [], 6: [], 7: [], 99: [] }
          if (_this.detailInfo.details.length > 0) {
            _this.detailInfo.details.map(({ orderType, ...rest }) => {
              _this.orderGrouped[orderType] = _this.orderGrouped[orderType] || []
              rest.orderType = orderType
              if ((orderType == 0 || orderType == 1) && _this.detailInfo.mzDrugOrgs) {
                let drugOrg = _this.detailInfo.mzDrugOrgs.filter((i) => i.id == rest.entityId)
                if (drugOrg[0]) {
                  rest.specification = drugOrg[0].mzDrug.specification
                  rest.specificationUnit = drugOrg[0].mzDrug.specificationUnit
                  rest.productFactory = drugOrg[0].mzDrug.productFactory
                  rest.isSeparate = drugOrg[0].isSeparate
                  rest.separateNumber = drugOrg[0].separateNumber
                  rest.separateNumberUnit = drugOrg[0].separateNumberUnit
                }
              }
              _this.orderGrouped[orderType].push(rest)
              if (orderType == 3 || orderType == 4 || orderType == 5 || orderType == 6 || orderType == 7) {
                _this.orderGrouped[99].push(rest)
              }
            })
            // console.log(_this.charge)
            // _this.$nextTick(function () {
            //   _this.defaultChecked()
            // })
          }
        },
        function (err) {
          console.log(err)
        }
      )
    },

    selectAllChange(selection, orderType) {
      if (this.type == 1) {
        // 如果选中的数目与请求到的数目相同就选中子节点，否则就清空选中
        if (selection && selection.length === this.orderGrouped[orderType].length) {
          selection.forEach((item) => {
            this.$set(item, 'checked', false)
          })
          switch (orderType) {
            case 0:
              this.$refs.multipleSelection0.clearSelection()
              break
            case 1:
              this.$refs.multipleSelection1.clearSelection()
              break
            case 2:
              this.$refs.multipleSelection2.clearSelection()
              break
            case 3:
              this.$refs.multipleSelection3.clearSelection()
              break
            case 4:
              this.$refs.multipleSelection4.clearSelection()
              break
            case 5:
              this.$refs.multipleSelection5.clearSelection()
              break
          }
        } else {
          this.orderGrouped[orderType].forEach((v, i) => {
            switch (orderType) {
              case 0:
                this.$refs.multipleSelection0.toggleRowSelection(v, true)
                break
              case 1:
                this.$refs.multipleSelection1.toggleRowSelection(v, true)
                break
              case 2:
                this.$refs.multipleSelection2.toggleRowSelection(v, true)
                break
              case 3:
                this.$refs.multipleSelection3.toggleRowSelection(v, true)
                break
              case 4:
                this.$refs.multipleSelection4.toggleRowSelection(v, true)
                break
              case 5:
                this.$refs.multipleSelection5.toggleRowSelection(v, true)
                break
            }
          })
        }
      }
    },
    defaultChecked() {
      if (this.orderGrouped[0].length > 0) {
        this.orderGrouped[0].forEach((v, i) => {
          this.$refs.multipleSelection0.toggleRowSelection(v, true)
        })
      }
    },
    /**
     * 已收费详情
     */
    paymentDetail() {
      var _this = this
      console.log('88999')
      _this.OutpatientDomain.PaymentedDetail(
        this.orderId,
        function (data) {
          _this.detailInfo = data.data
          _this.patientId = _this.detailInfo.patientId
          _this.patientDiagnoseId = _this.detailInfo.patientDiagnoseId

          console.log('999')
          if (_this.patientId) {
            _this.getDetail()
            console.log('8888')
            _this.getPatientDiagnose()
            _this.getMZPrescriptionDetail()
          }
          //orderType 0直接售药 1处方 2检查治疗项 3附加收费 4代煎费用 5审方费用 6快递费 7包装费
          _this.orderGrouped = { 0: [], 1: [], 2: [], 3: [], 4: [], 5: [], 6: [], 7: [], 99: [] }
          if (_this.detailInfo.details.length > 0) {
            console.log('333')
            _this.detailInfo.details.map(({ orderType, ...rest }) => {
              _this.orderGrouped[orderType] = _this.orderGrouped[orderType] || []
              rest.orderType = orderType
              if ((orderType == 0 || orderType == 1) && _this.detailInfo.mzDrugOrgs) {
                let drugOrg = _this.detailInfo.mzDrugOrgs.filter((i) => i.id == rest.entityId)
                if (drugOrg[0]) {
                  rest.specification = drugOrg[0].mzDrug.specification
                  rest.specificationUnit = drugOrg[0].mzDrug.specificationUnit
                  rest.productFactory = drugOrg[0].mzDrug.productFactory
                  rest.isSeparate = drugOrg[0].isSeparate
                  rest.separateNumber = drugOrg[0].separateNumber
                  rest.separateNumberUnit = drugOrg[0].separateNumberUnit
                }
              }

              console.log('222')
              _this.orderGrouped[orderType].push(rest)
              if (orderType == 3 || orderType == 4 || orderType == 5 || orderType == 6 || orderType == 7) {
                _this.orderGrouped[99].push(rest)
              }
              // if(orderType == 0){
              //   _this.$refs.multipleSelection0.toggleRowSelection(rest, true)
              // }
            })
            console.log('111')
            _this.detailInfo.showTotalMoney = _this.detailInfo.totalMoney
            if (_this.orderGrouped[5].length > 0) {
              _this.detailInfo.showTotalMoney = (_this.detailInfo.totalMoney - _this.orderGrouped[5][0].countMoney).toFixed(2)
            }
            _this.refund.totalMoney = 0
            _this.refund.details = []
            if (_this.type < 3) {
              _this.refund.details = [
                ..._this.orderGrouped[0],
                ..._this.orderGrouped[1],
                ..._this.orderGrouped[2],
                ..._this.orderGrouped[3],
                ..._this.orderGrouped[4],
                // ..._this.orderGrouped[5],
                ..._this.orderGrouped[6],
                ..._this.orderGrouped[7],
              ]
              _this.refund.details.forEach((item) => {
                //prescriptionType处方类型 0方剂(饮片) 处方 1 成药处方 2 输液处方
                if (item.orderType == 1 && item.prescriptionType == 0) {
                  _this.refund.totalMoney = _this.refund.totalMoney + item.countMoney * parseInt(item.dosage)
                } else {
                  _this.refund.totalMoney = _this.refund.totalMoney + item.countMoney
                }
                // if(item.orderType == 0){
                //   _this.refund.totalMoney=0
                // }
              })
              _this.refund.payments[0].payMoney = _this.refund.totalMoney - _this.detailInfo.discountsMoney - _this.detailInfo.debtMoney
            }
            _this.$nextTick(function () {
              _this.defaultChecked()
            })
          }
        },
        function (err) {
          console.log(err)
        }
      )
    },
    getDetail() {
      var _this = this
      _this.patientDomain.GetDetail(
        _this.patientId,
        function (data) {
          _this.patientInfo = data.data
        },
        function (err) {}
      )
    },

    getPatientDiagnose() {
      var _this = this
      _this.patientDomain.GetPatientDiagnoseDetail(
        _this.patientDiagnoseId,
        function (data) {
          _this.patientDiagnoseInfo = data.data
        },
        function (err) {}
      )
    },
    getMZPrescriptionDetail() {
      var _this = this
      _this.OutpatientDomain.MZPrescriptionDetail(
        _this.patientDiagnoseId,
        function (data) {
          _this.mZPrescriptionInfo = data.data
          _this.getPrintPrescription()
        },
        function (err) {}
      )
    },
    getPrintPrescription() {
      var _this = this
      _this.patientDomain.MZPrintPrescription(
        _this.patientDiagnoseId,
        this.mZPrescriptionInfo.mzPrescriptionOut.waitAuditUserId ? this.mZPrescriptionInfo.mzPrescriptionOut.waitAuditUserId : this.mZPrescriptionInfo.mzPrescriptionOut.userId,
        function (data) {
          _this.mZPrintPrescription = data.data
        },
        function (err) {}
      )
    },
    CloseChargeBtn(formName) {
      this.setChargeDialog = false
      this.$refs[formName].resetFields()
    },
    closeRefundBtn(formName) {
      this.setPCRefundDialog = false
      this.setRefundDialog = false
      this.$refs[formName].resetFields()
    },
    toCharge() {
      if (this.charge.details.length == 0) {
        this.$message({
          message: '请选择收费项目',
          type: 'warning',
        })
        return
      }
      this.charge.payment = this.charge.totalMoney - this.charge.discountsMoney
      this.setChargeDialog = true
    },
    toRefundAll() {
      var _this = this
      _this.OutpatientDomain.DrugRefund(
        _this.refund,
        function (data) {
          _this.$router.push({
            path: './CreateOrder',
            query: {
              orderId: _this.orderId,
            },
          })
        },
        function (error) {
          let jsonObj = JSON.parse(error.msg)
          _this.$message({
            message: jsonObj.msg,
            type: 'error',
          })
          console.log(error)
        }
      )
    },
    toAgreeRefund() {
      this.refund.totalMoney = (this.refund.totalMoney * 1).toFixed(2)
      this.refund.payments[0].payMoney = this.refund.totalMoney
      this.setPCRefundDialog = true
      this.refund.isOk = true
      console.log(this.refund)
    },
    toAuditRefund() {
      this.refund.totalMoney = (this.refund.totalMoney * 1).toFixed(2)
      this.refund.payments[0].payMoney = this.refund.totalMoney
      this.setPCRefundDialog = true
      this.refund.isOk = false
      console.log(this.refund)
    },
    toRefund() {
      if (this.detailInfo.discountsMoney == 0) {
        console.log(this.refund.details.length)
        if (!this.refund.details.length) {
          this.$message({
            message: '请选择退费项目',
            type: 'warning',
          })
          return
        }
        this.refund.payments[0].payMoney = this.refund.totalMoney - this.detailInfo.discountsMoney - this.detailInfo.debtMoney
      }
      if (this.refund.details.length == 0 && this.orderGrouped[1].length > 0) {
        this.$message({
          message: '处方不可退',
          type: 'warning',
        })
        return
      }

      this.setRefundDialog = true
    },
    DrugRefund() {
      var _this = this
      _this.OutpatientDomain.DrugRefund(
        _this.refund,
        function (data) {
          _this.$router.push({
            path: './CreateOrder',
            query: {
              orderId: _this.orderId,
            },
          })
        },
        function (error) {
          let jsonObj = JSON.parse(error.msg)
          _this.$message({
            message: jsonObj.msg,
            type: 'error',
          })
          console.log(error)
        }
      )
    },
    saveRefund(formName) {
      var _this = this
      _this.$refs[formName].validate((valid) => {
        if (valid) {
          const loading = this.$loading({
            lock: true,
            background: 'rgba(0, 0, 0, 0.5)',
          })
          _this.OutpatientDomain.DrugRefund(
            _this.refund,
            function (data) {
              loading.close()
              _this.$message({
                message: '操作成功',
                type: 'success',
              })
              _this.setRefundDialog = false
              _this.setPCRefundDialog = false
              _this.$router.go(-1)
              // if (_this.detailInfo.discountsMoney == 0) {
              //   _this.$router.go(-1)
              // } else {
              //   _this.$router.push({
              //     path: './CreateOrder',
              //     query: {
              //       orderId: _this.orderId,
              //     }
              //   })
              // }
            },
            function (error) {
              loading.close()
              let jsonObj = JSON.parse(error.msg)
              _this.$message({
                message: jsonObj.msg,
                type: 'error',
              })
              console.log(error)
            }
          )
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
    goBack() {
      this.$router.go(-1)
    },
    addPatient() {
      this.$router.push({
        path: './AddPatient',
        query: {},
      })
    },
  },
}
</script>
<style scoped lang="scss">
.iq-card-body {
  padding: 0 0 15px;
  height: auto;

  .table-top {
    width: 96%;
    margin: 0 auto;
  }

  .table-line {
    width: 100%;
    height: 6px;
    background: #f5f6f7;
    margin: 0 0 15px;
  }

  .table-content {
    padding: 0 40px;

    .top-info {
      .t1 {
        margin-right: 30px;
      }

      .t2 {
        color: #999999;
      }

      .right-i {
        margin-right: 10%;
      }

      .userName {
        margin-left: 10px;
      }

      .info-div {
        margin-top: 10px;
      }
    }

    .title {
      height: 60px;
      line-height: 60px;
      font-weight: bold;
    }

    ::v-deep .el-table__row > td {
      /* 去除表格线 */
      // border: none !important;
    }

    ::v-deep .el-table th.is-leaf {
      /* 去除上边框 */
      border: none !important;
    }

    ::v-deep .el-table::before {
      /* 去除下边框 */
      height: 0 !important;
    }

    ::v-deep .el-table .el-table__body-wrapper .el-table__cell {
      padding: 10px 0 !important;
    }

    .my-table {
      border: 1px solid #ebeef5;
      padding: 10px 5px;
    }

    .el-form-item .el-form-item {
      margin-bottom: 20px;
    }

    .leftInput {
      width: 100%;
      position: relative;
    }

    .rightTip {
      position: absolute;

      top: 0;
      z-index: 2;
      color: #999999;
    }

    ::v-deep .el-input-number .el-input__inner {
      text-align: left;
    }

    .numDiv {
      padding: 15px;
      background: #fff7f7;
      border: 1px solid #f4d3d3;
      border-radius: 8px;
      margin: 20px 0;

      .num1 {
        margin: 0 10px;
      }

      .num2 {
        color: #ff0000;
      }

      ::v-deep .num2 .el-input__inner {
        color: #ff0000;
      }

      .inputNum {
        color: #ff0000;
      }
    }

    .numDiv2 {
      background: #fafafa;
      border: 1px solid #e6e6e6;

      .right-num {
        // margin-right: 10%;
      }

      .center-num {
        // margin: 0 20px;
      }

      .desc {
        margin-right: 2%;
        width: 32%;
      }

      .desc:nth-child(3n) {
        margin-right: 0;
      }

      .type0 {
        color: #67c23a !important;
      }

      .type1 {
        color: #f56c6c !important;
      }

      .type2 {
        color: #e6a23c !important;
      }

      .t1 {
        margin-right: 20px;
      }

      .t2 {
        color: #999999;
      }

      .t3 {
        color: #fe0000;
      }

      .num1 {
        margin: 0 10px;
      }

      ::v-deep .num2 .el-input__inner {
        color: #ff0000;
      }

      .inputNum {
        color: #ff0000;
      }
    }

    .infoDiv {
      // margin: 0 20px;

      .t1 {
        margin-right: 20px;
      }

      .t2 {
        color: #5c83f2;
      }
    }
  }
}
</style>
